<template>
  <div class="main">
    <ul class="containList">
      <li class="listItem" v-for="(item, index) in noteList" :key="index">
        <div class="comerName" @click="go(item.noteContent)">{{ index + 1 }}、{{ item.noteName }}</div>
        <!-- <div style="color:#5192fd;">注释链接地址</div> -->
        <!-- <a :href="item.notecontent"></a> -->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      monerList: [
        { name: '关于分析仪的安装说明-P145', link: '' },
        { name: '关于进样器的安装说明-P225', link: '' },
        { name: '关于排污管的清洗说明-P317', link: '' },
        { name: '关于排污管的清洗说明视频', link: '' },
        { name: '关于排污管的正确链接图示', link: '' },
      ],
      noteList: []
    }
  },
  methods: {
    getNoteList() {
      let that = this
      let data = {}
      // data.memberCode = that.$route.query.memberCode
      data.typeCode = that.$route.query.typeCode
      // data.typeCode = '516684771'

      console.log(data);

      // data.memberCode = '774026'
      // data.typeCode = '841629625'
      that.$http.post('/machineTypeNote/queryMachineTypeNoteList', data).then(res => {
        // console.log("笔记列表数据", res);
        this.noteList = res.data.data
      })
    },
    go(src) {
      window.open(src)
    }
  },
  created() { },
  mounted() {
    this.getNoteList()
  }
}
</script>

<style scoped>
.main {
  height: calc(100vh - 146px);
  width: 100vw;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.listItem {
  margin: 20px 40px;
  font-size: 36rpx;
  color: #409eff;
}

.comerName {
  margin-bottom: 5px;
}
</style>